import React from 'react';

import Layout from './layout.js';
import DynamicLink from 'components/DynamicLink';

const About = () => (
  <Layout>
    <div className="about__wrapper">
      <div className="header__topper">
        <DynamicLink className="header__name-link" to="/">
          Caitlin Wang —{' '}
        </DynamicLink>
        <h1 className="header__title">About</h1>
      </div>
      <div className="about__content">
        <p>
          Hi I’m Caitlin! I’m an engineer who cares about building
          software and sites that are mindful and inclusive.
        </p>
        <p>
          I grew up in many places including Boston, Philadelphia, and Shanghai. But these days I live in Brooklyn and build
          websites and apps at{' '}
          <DynamicLink to="https://upstatement.com">Upstatement</DynamicLink>. You can see some of my development work <DynamicLink to="/work">here</DynamicLink>.
        </p>
        <p>
          Though to be honest, I'm usually not at my computer if I can help it. I like to spend my free time biking around the city, indoor rock climbing, and working on my <DynamicLink to="https://www.ravelry.com/projects/bobocrocro">fiber art projects</DynamicLink>. 
        </p>
        <p>
          Thanks for dropping by and feel free to say hello via{' '}
          <DynamicLink to="mailto:caitlinwang97@gmail.com">email</DynamicLink>!
        </p>
      </div>
    </div>
  </Layout>
);

export default About;
